<!--
  PACKAGE_NAME : src/pages/euc/phone/request/type
  FILE_NAME : table.vue.vue
  AUTHOR : jhcho
  DATE : 2024-05-23
  DESCRIPTION :
-->
<template>
  <div class="container">
    <div class="page-sub-box">
      <table class="table_form line-bin">
        <colgroup>
          <col style="width: 150px; height: 40px">
        </colgroup>

        <thead class="sub_title_txt">
        <tr>
          <td>
            <h2 class="sub-title-bin">테이블 설정</h2>
          </td>
        </tr>
        </thead>
        <tbody>
          <tr>
            <th class="sub_title_txt">기본 항목</th>
            <td>
              <span class="check-type col">
                <DxCheckBox
                    id="userNm"
                    text="이름"
                    :value="baseColumns.userNm"
                    @value-changed="onChangeUserColumn"
                />
              </span>
              <span class="check-type col">
                <DxCheckBox
                    id="prevUserNm"
                    text="이전 사용자"
                    :value="baseColumns.prevUserNm"
                    @value-changed="onChangeColumn"
                />
              </span>
              <span class="check-type col">
                <DxCheckBox
                    id="comment"
                    :text="$_msgContents('UC.WORD.MEMO', { defaultValue: '메모' })"
                    :value="baseColumns.comment"
                    @value-changed="onChangeColumn"
                />
              </span>
              <span class="check-type col">
                <DxCheckBox
                    id="phoneModel"
                    :text="$_msgContents('UC.WORD.MODEL', { defaultValue: '모델' })"
                    :value="baseColumns.phoneModel"
                    @value-changed="onChangeColumn"
                />
              </span>
              <span class="check-type col">
                <DxCheckBox
                    id="recordFl"
                    :text="$_msgContents('UC.WORD.RECORD', { defaultValue: '녹취' })"
                    :value="baseColumns.recordFl"
                    @value-changed="onChangeColumn"
                />
              </span>
              <span class="check-type col">
                <DxCheckBox
                    id="startDt"
                    :text="$_msgContents('UC.WORD.START_DATE', { defaultValue: '시작일' })"
                    :value="baseColumns.startDt"
                    @value-changed="onChangeColumn"
                />
              </span>
              <span class="check-type col">
                <DxCheckBox
                    id="endDt"
                    :text="$_msgContents('UC.WORD.END_DATE', { defaultValue: '종료일' })"
                    :value="baseColumns.endDt"
                    @value-changed="onChangeColumn"
                />
              </span>
              <span class="check-type col">
                <DxCheckBox
                    id="contactNumber"
                    text="연락 번호"
                    :value="baseColumns.contactNumber"
                    @value-changed="onChangeColumn"
                />
              </span>
              <span class="check-type col">
                <DxCheckBox
                    id="phoneLocation"
                    text="근무지"
                    :value="baseColumns.phoneLocation"
                    @value-changed="onChangeColumn"
                />
              </span>
              <span class="check-type col">
                <DxCheckBox
                    id="phoneNumber"
                    :text="$_msgContents('UC.WORD.NUMBER', { defaultValue: '내선번호' })"
                    :value="baseColumns.phoneNumber"
                    @value-changed="onChangeColumn"
                />
              </span>
              <span class="check-type col">
                <DxCheckBox
                    id="forwardNumber"
                    :text="$_msgContents('UC.WORD.FORWARD_NUMBER', { defaultValue: '착신번호' })"
                    :value="baseColumns.forwardNumber"
                    @value-changed="onChangeColumn"
                />
              </span>
            </td>
          </tr>
          <tr>
            <th class="sub_title_txt">
              <div class="flex items-center justify-between">
                <span>추가 항목</span>
                <DxButton
                    class="cursor-pointer"
                    icon="plus"
                    styling-mode="contained"
                    @click="onShowNewCustomColumn"
                />
              </div>
            </th>
            <td>
              <template v-for="(item, index) in customColumns">
                <span class="check-type col" :key="index">
                  <DxCheckBox
                      :id="item.dataField"
                      :text="item.caption"
                      :value="item.checked"
                      @value-changed="(e) => onChangeColumn(e, item)"
                  />
                </span>
              </template>
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <th class="sub_title_txt">테이블 사용 여부</th>
            <td>
              <div class="flex items-center">
                <DxSwitch v-model="viewFl" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="page-sub-box" v-if="dataGrid.columns.length > 0">
      <table class="table_form line-bin">
        <colgroup>
          <col style="width:130px;">
          <col style="width:auto;">
        </colgroup>

        <thead class="sub_title_txt">
        <tr>
          <td>
            <h2 class="sub-title-bin">테이블 미리보기</h2>
          </td>
        </tr>
        </thead>
      </table>
      <DxDataGrid :key="dataGridKey" :data-source="[{}]">
        <template v-for="(column, index) in dataGrid.columns">
          <template v-if="column.multiHeaderNm">
            <DxColumn :key="index" :caption="column.multiHeaderNm">
              <DxColumn
                  v-for="(column2, index2) in column.columns"
                  :key="`${index}_${index2}`"
                  alignment="center"
                  :visible="column2.visible"
                  :caption="column2.caption"
                  :data-field="column2.dataField"
                  :data-type="column2.dataType"
                  :header-cell-template="column2.headerCellTemplate"
              >
              </DxColumn>
            </DxColumn>
          </template>
          <template v-else>
            <DxColumn
                :key="index"
                alignment="center"
                :visible="column2.visible"
                :caption="column.caption"
                :data-field="column.dataField"
                :data-type="column.dataType"
                :header-cell-template="column.headerCellTemplate"
            >
            </DxColumn>
          </template>
        </template>
      </DxDataGrid>
    </div>
    <section class="terms bottom-btn-box">
      <div class="page-sub-box">
        <h2 class="hidden">일반 버튼</h2>
        <div class="bottom-btn-wrap">
          <DxButton
              text="등 록"
              :width="120"
              :height="40"
              class="default filled txt_S medium"
              :use-submit-behavior="true"
              :disabled="isSaving"
              :onClick="onSave"
          />
          <DxButton
              text="취 소"
              :width="120"
              :height="40"
              class="white filled txt_S medium"
              :onClick="() => $_goPrePage()"
          />
        </div>
      </div>
    </section>
    <DxPopup
        v-if="modal.isOpened"
        :show-title="true"
        :title="modal.initData ? modal.initData.title : null"
        :width="modal.initData ? modal.initData.width : null"
        :height="modal.initData ? modal.initData.height : null"
        :drag-enabled="true"
        :resize-enabled="true"
        :show-close-button="true"
        :close-on-outside-click="false"
        :visible="modal.isOpened"
        @hiding="isOpenModal(false)"
    >
      <template #content>
        <div>
          <transition>
            <div class="page-sub-box">
              <table class="table_form line-bin">
                <colgroup>
                  <col style="width: 200px;" />
                  <col style="width: auto;" />
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="row" class="alT">
                      <label>컬럼명 *</label>
                    </th>
                    <td>
                      <DxTextBox
                          v-model="newCustomColumn.caption"
                          styling-mode="outlined"
                          :width="170"
                          style="box-shadow: none !important;"
                      >
                        <DxValidator validation-group="ValidationGroupName">
                          <DxRequiredRule message="컬럼명은 필수입니다.." />
                        </DxValidator>
                      </DxTextBox>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </transition>
        </div>
      </template>

      <DxToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="center"
          :options="{
						elementAttr: {
							class: 'default filled txt_S medium',
						},
						text: '적용',
						width: '120',
						height: '40',
						onClick: () => this.onSaveNewCustomColumn()
					}"
      />

      <DxToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="center"
          :options="{
						elementAttr: {
							class: 'white filled txt_S medium',
						},
						text: '닫기',
						width: '120',
						height: '40',
						onClick: () => this.isOpenModal(false)
					}"
      />
    </DxPopup>
  </div>
</template>
<script>
import { isSuccess } from '@/plugins/common-lib';
import {
  DxColumn,
  DxDataGrid,
} from 'devextreme-vue/data-grid';
import { DxButton } from 'devextreme-vue/button';
import { DxCheckBox } from 'devextreme-vue/check-box';
import DefaultTableModule from './default-table';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import { DxRequiredRule, DxValidator } from 'devextreme-vue/validator';
import { DxTextBox } from 'devextreme-vue/text-box';
import { DxSwitch } from "devextreme-vue/switch";

export default {
  components: {
    DxSwitch,
    DxToolbarItem,
    DxValidator,
    DxTextBox,
    DxRequiredRule,
    DxPopup,
    DxColumn,
    DxDataGrid,
    DxButton,
    DxCheckBox
  },
  data() {
    return {
      isSaving: false,
      reqParams: {},
      tableId: null,
      dataGridKey: 0,
      dataGrid: {
        columns: []
      },
      modal: {
        isOpened: false,
        currentComponent: null,
        initData: {},
        sendData: {},
      },
      newCustomColumn: {
        caption: '',
        dataField: '',
      },
      customColumns: [],
      userColumns: [],
      deptColumns: [],
      defaultColumns: [],
      viewFl: true,
      baseColumns: {
        userNm: false,
        deptNm: false,
        prevUserNm: false,
        comment: false,
        phoneModel: false,
        recordFl: false,
        startDt: false,
        endDt: false,
        contactNumber: false,
        phoneLocation: false,
        phoneNumber: false,
        forwardNumber: false,
      }
    }
  },
  methods: {
    async onSave() {
      this.isSaving = true;
      const payload = {
        actionname: 'EUC_PHONE_REQUEST_TYPE_TABLE_UPDATE',
        data: [{
          id: this.tableId,
          typeId: this.reqParams.id,
          columns: JSON.stringify(this.dataGrid.columns),
          viewFl: this.viewFl ? 'Y' : 'N',
        }],
        loading: true
      }

      const res = await this.CALL_EUC_API(payload);
      if(isSuccess(res)) {
        await this.$_Msg('저장되었습니다.', { icon: 'success' })
        this.$_goPrePage();
      }
      this.isSaving = false;
    },
    /**
     * @description 새로운 커스텀 컬럼 생성 저장
     * */
    onSaveNewCustomColumn() {
      if(this.newCustomColumn.caption === '') {
        this.$_Msg('컬럼명을 입력해주세요.', { icon: 'error' })
      } else {
        this.customColumns.push({
          caption: this.newCustomColumn.caption,
          dataField: `customColumn${this.customColumns.length + 1}`,
          checked: false,
        });
        this.isOpenModal(false);
      }
    },
    onShowNewCustomColumn(options){
      if(this.customColumns.length >= 5) {
        this.$_Msg('컬럼 추가는 최대 5개까지 생성 가능합니다.', { icon: 'error' })
        return;
      } else {
        this.onOpenModal(
            'ModalNewCustomColumn',
            {
              title: `커스텀 컬럼 생성`,
              width: '500',
              height: '250',
            },
            options
        );
      }
    },
    /** @description: 팝업 창 열때 이벤트 */
    onOpenModal(componentNm, componentInitData, sendData){
      this.modal.currentComponent = componentNm;
      this.modal.initData = componentInitData;
      this.modal.sendData = sendData;
      this.isOpenModal(true);
    },
    isOpenModal(data) {
      this.modal.isOpened = data;
      if(!data) {
        this.modal.currentComponent = null;
        this.modal.initData = {};
        this.modal.contentData = null;
        this.newCustomColumn.caption = '';
        this.newCustomColumn.dataField = '';
      }
    },
    /**
     * @description 사용자 컬럼 추가
     * */
    addUserColumn() {
      const defaultUserColumns = this.userColumns;
      const findColumn = this.dataGrid.columns.find(column => column.multiHeaderNm === defaultUserColumns[0].multiHeaderNm);
      if(!findColumn) {
        this.dataGrid.columns.unshift(...defaultUserColumns)
      }
    },
    /**
     * @description 사용자 컬럼 삭제
     * */
    removeUserColumn() {
      const defaultUserColumns = this.userColumns;
      this.dataGrid.columns = this.dataGrid.columns.filter(column => column.multiHeaderNm !== defaultUserColumns[0].multiHeaderNm);
    },
    /**
     * @description 사용자 컬럼 추가
     * */
    onChangeUserColumn(e) {
      if(e.value) {
        this.addUserColumn();
      } else {
        this.removeUserColumn();
      }
    },
    /**
     * @description 컬럼 변경 이벤트
     * */
    onChangeColumn(e, item) {
      const id = e.element.id;
      const multiHeaderNm = item?.multiHeaderNm || this.reqParams.typeNm;
      const column = this.getOrCreateColumn(multiHeaderNm);

      if (e.value) {
        this.addColumn(column, id, item, e);
      } else {
        this.removeColumn(column, id, multiHeaderNm);
      }

      this.dataGridKey += 1;
    },
    /**
     * @description 컬럼이 없으면 생성하고 있으면 반환
     * */
    getOrCreateColumn(typeNm) {
      let column = this.dataGrid.columns.find(column => column.key === 'CONTENT');
      if (!column) {
        column = {
          key: 'CONTENT',
          multiHeaderNm: typeNm,
          columns: []
        };
        this.dataGrid.columns.push(column);
      }
      return column;
    },
    /**
     * @description 컬럼 추가
     * */
    addColumn(column, id, item, e) {
      const findColumn = column.columns.find((column) => column.dataField === id);
      if (!findColumn) {
        const targetColumn = this.defaultColumns.find((item) => item.dataField === id);
        const newColumn = targetColumn || {
          caption: item?.caption || e.component._props.text,
          dataField: id,
          height: 40,
          alignment: 'center',
          visible: true,
          allowEditing: true,
          sortOrder: 'none',
          allowHeaderFiltering: false,
          allowGrouping: false,
        };
        column.columns.push(newColumn);
      }
    },
    /**
     * @description 컬럼 삭제
     * @param column
     * @param id
     * @param typeNm
     * */
    removeColumn(column, id, multiHeaderNm) {
      column.columns = column.columns.filter(column => column.dataField !== id);
      if (!column.columns.length) {
        this.dataGrid.columns = this.dataGrid.columns.filter(column => column.multiHeaderNm !== multiHeaderNm);
      }
    },
    /**
     * @descipriotn 커스텀 컬럼 추가
     * @param column
     * */
    setCustomColumn(column) {
      if (column.dataField.includes('customColumn')) {
        this.customColumns.push({
          caption: column.caption,
          dataField: column.dataField,
          checked: true,
        });
      } else {
        this.baseColumns[column.dataField] = true;
      }
    },
    /**
     * @description 헤더 셀 템플릿 설정
     * @param column
     * */
    setHeaderCellTemplate(column) {
      if(column.dataField === 'selectUser') {
        column.headerCellTemplate = (container) => {
          const icon = document.createElement('i');
          icon.className = 'dx-icon dx-icon-user';
          container.append(icon);
        }
      }
    },
    /**
     * @description 컬럼 변환
     * */
    convertColumns() {
      if (this.reqParams?.requestTypeTable) {
        const data = this.reqParams.requestTypeTable;
        if (data?.id) {
          this.tableId = data.id;
          this.viewFl = data.viewFl === 'Y';

          this.dataGrid.columns = JSON.parse(data.columns).map((item) => {
            return {
              key: item.key,
              multiHeaderNm: item.key === 'CONTENT' ? this.reqParams.typeNm : item.multiHeaderNm,
              columns: item.columns.map((column) => {
                this.setCustomColumn(column);
                this.setHeaderCellTemplate(column);
                return column;
              })
            }
          });
        }
      }
    }
  },
  mounted() {
    this.convertColumns();
  },
  created() {
    this.reqParams = this.$store.getters.getDetailParams;
    this.userColumns = DefaultTableModule.userColumns(this);
    this.deptColumns = DefaultTableModule.deptColumns(this);
    this.defaultColumns = DefaultTableModule.defaultColumns(this);
  },
}
</script>
<style scoped>
</style>